<template>
  <div class="product-description row" v-if="tabs.length > 0">
    <!-- v-tabs per visibilità desktop -->
    <template v-if="!$vuetify.breakpoint.xs">
      <v-tabs v-model="selectedTab">
        <v-tab v-for="tab in tabs" :key="tab.title" :class="tab.cssClass">
          {{ tab.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item v-for="tab in tabs" :key="tab.title" :class="tab.cssClass">
          <div
            v-for="item in tab.items"
            :key="item.title"
            class="pb-4"
            :class="item.cssClass"
          >
            <h4 v-if="item.title">{{ item.title }}</h4>

            <component
              v-for="path in item.paths"
              :key="path.key"
              :is="path.template || 'DefaultTpl'"
              :content="path.content"
              :append="path.append"
            />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <template v-else>
      <v-expansion-panels class="mb-2" accordion flat :focusable="selectedTab">
        <v-expansion-panel
          v-for="tab in tabs"
          :key="tab.title"
          :class="tab.cssClass"
        >
          <v-expansion-panel-header class="secondary lighten-1">
            <h3 class="secondary--text" v-html="tab.title"></h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mt-2">
            <div
              v-for="item in tab.items"
              :key="item.title"
              class="pb-4"
              :class="item.cssClass"
            >
              <h4 v-if="item.title">{{ item.title }}</h4>
              <div v-for="path in item.paths" :key="path.key">
                <component
                  :is="path.template || 'DefaultTpl'"
                  :content="path.content"
                  :append="path.append"
                />
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </div>
</template>

<style lang="scss"></style>

<script>
import tabsConfig from "/public/product/descriptionTabsConfig.json";
import get from "lodash/get";
import DefaultTpl from "./templates/DefaultTpl.vue";
import MediaTpl from "./templates/MediaTpl.vue";
import kebabCase from "lodash/kebabCase";

export default {
  name: "ProductDescription",
  /* eslint-disable vue/no-unused-components */
  components: {
    DefaultTpl,
    MediaTpl
  },
  data() {
    return {
      selectedTab: null,
      productDescriptionTabs: tabsConfig
    };
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    productMetadata: {
      type: Object
    },
    ingredientList: {
      type: Object
    }
  },
  computed: {
    tabs() {
      let tabs = [];
      this.productDescriptionTabs.forEach(tabMeta => {
        let items = [];
        tabMeta.items.forEach(parMeta => {
          let paths = [];
          parMeta.paths.forEach(pathMeta => {
            let content = get(this.product, pathMeta.data || pathMeta);
            let append = pathMeta.append;
            // check if content is empty
            if (content && content !== "<p></p>") {
              // check if table has empty cells
              if (pathMeta.template === "TableTpl") {
                let cells = JSON.parse(content).cells;
                console.log(cells);
                let hasContent = cells.find(c => {
                  return c !== null;
                });
                if (!hasContent) {
                  return;
                }
              }
              if (pathMeta.template === "ValueTpl") {
                let value = JSON.parse(content).value;
                if (!value) {
                  return;
                }
              }
              paths.push({
                key: pathMeta.data || pathMeta,
                content: content,
                template: pathMeta.template,
                append: append
              });
            }
          });
          if (paths.length > 0) {
            items.push({
              title: parMeta.title,
              paths: paths,
              cssClass: get(parMeta, "cssClass", kebabCase(parMeta.title))
            });
          }
        });
        if (items.length > 0) {
          tabs.push({
            title: tabMeta.title,
            items: items,
            cssClass: get(tabMeta, "cssClass", kebabCase(tabMeta.title))
          });
        }
      });
      return tabs;
    }
  }
  // ,
  // // TEST MODE
  // created() {
  //   // this.product.metaData.product_description.MEDIA =
  //   //   '{"image": "/img/contact-store.jpg", "url":"https://www.youtube.com/watch?v=t9disK9ixpY", "type":"video/mp4"}';
  //   // this.product.metaData.product_description.VIDEO =
  //   // esempi di URL funzionanti oppure gestiti da MediaTpl
  //   // "https://www.youtube.com/watch?v=t9disK9ixpY"
  //   // "https://www.youtube.com/embed/t9disK9ixpY";
  //   // "https://vimeo.com/249104595"
  //   // "https://player.vimeo.com/video/249104595"
  //   //   "https://samplelib.com/lib/preview/mp4/sample-5s.mp4";
  // }
  // END TEST MODE
};
</script>
