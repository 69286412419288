<template>
  <v-row v-if="product">
    <v-col
      xl="6"
      lg="6"
      md="6"
      sm="12"
      cols="12"
      order="1"
      order-sm="1"
      class="pa-3"
    >
      <div class="mb-4 d-flex d-md-none">
        <!-- product name in visibilità mobile -->
        <v-btn
          v-if="$platform_is_cordova && $routerHistory.hasPrevious()"
          elevation="0"
          outlined
          dark
          x-small
          :to="{ path: $routerHistory.previous().path }"
          class="back-button px-0 pt-2"
          aria-label="Torna Indietro"
          ><v-icon medium elevation="0">mdi-chevron-left</v-icon>
          <span class="back-button-sm d-none d-sm-flex d-md-none">
            Indietro
          </span></v-btn
        >
        <div class="d-sm-none">
          <div itemprop="name" class="product-name mobile">
            {{ product.name }}
          </div>
          <div itemprop="name" class="product-name mobile">
            {{ product.description }}
          </div>
          <!-- product brand in visibilità mobile -->
          <div class="product-brand mobile">
            {{ product.shortDescr }}
          </div>
        </div>
      </div>
      <v-card flat v-if="selectedImage" class="image-preview-wrapper">
        <!-- promo, limite acquisto visualizzato su phone -->
        <div class="d-flex d-sm-none">
          <div
            class="promo-box text-uppercase product-badge novita mr-2"
            v-if="product.newProduct"
          >
            Novità
          </div>
          <ProductPromo
            :warehousePromo="product.warehousePromo"
            v-if="product.warehousePromo"
          />
          <ProductAcqLimit :product="product" class="mr-1" />
        </div>
        <ProductImages
          :images="product.media"
          :name="product.name"
          :code="productCode"
          :key="product.productId"
          :highlight="highlight"
          :heartKey="heartKey"
          @toggleFavourites="addToFavorites"
        />
      </v-card>
    </v-col>

    <v-col
      xl="6"
      lg="6"
      md="6"
      sm="12"
      cols="12"
      order="2"
      order-sm="2"
      class="pa-3"
    >
      <div>
        <!-- product code in visibilità desktop -->
        <div class="d-none d-sm-flex mb-2">
          <span class="product-code">Cod. {{ productCode }}</span>
        </div>
        <!-- promo e limite acquisto per desktop -->
        <div class="d-none d-sm-flex">
          <div
            class="promo-box text-uppercase product-badge novita mr-2"
            v-if="product.newProduct"
          >
            Novità
          </div>
          <ProductPromo
            :warehousePromo="product.warehousePromo"
            v-if="product.warehousePromo"
          />
          <ProductAcqLimit :product="product" class="mr-1" />
        </div>
        <!-- product name in visibilità desktop -->
        <div itemprop="name" class="product-name hidden-xs-only">
          {{ product.name }}
        </div>
        <div itemprop="name" class="product-name hidden-xs-only">
          {{ product.description }}
        </div>
        <!-- product brand in visibilità desktop -->
        <div class="product-brand hidden-xs-only">
          {{ product.shortDescr }}
        </div>
        <div
          class="product-descr"
          v-if="
            product.metaData &&
              product.metaData.product_info &&
              product.metaData.product_info.weight_description
          "
        >
          {{ product.metaData.product_info.weight_description }}
        </div>
        <div
          v-if="
            product.metaData &&
              product.metaData.product_seo &&
              product.metaData.product_seo.seo_description
          "
          class="product-seo-descr"
          v-html="product.metaData.product_seo.seo_description"
          @click="clicked"
        ></div>
        <div class="product-classes-wrapper">
          <ProductClass v-if="productVendor" :pClass="productVendor" />
          <ProductClass
            :pClass="pClass"
            v-for="pClass in productClasses"
            :key="pClass.productClassId"
          />
        </div>
        <div class="product-info-frozen">
          <!-- surgelato -->
          <v-tooltip
            left
            v-if="product.metaData.product_info.IS_SURGELATO == true"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="product-info mr-4" v-bind="attrs" v-on="on">
                <img
                  aria-hidden
                  alt="Immagine surgelato"
                  src="/product-info/surgelato.svg"
                />
              </div>
            </template>
            <span>Surgelato</span>
          </v-tooltip>
        </div>
        <v-row>
          <v-col
            cols="12"
            sm="3"
            md="12"
            lg="12"
            xl="3"
            class="mt-md-2 text-sm-left text-md-letf"
          >
            <ProductPrice
              class="text-center text-sm-left"
              :product="product"
              :showStandard="false"
            ></ProductPrice>
          </v-col>
          <v-col
            cols="12"
            sm="9"
            md="12"
            lg="12"
            xl="9"
            class="handle-quantity mt-md-2 mt-lg-0 text-sm-left text-xs-center"
          >
            <div
              class="d-flex flex-row justify-lg-end justify-sm-start align-center"
              v-if="product.available > 0"
            >
              <div class="qty-wrap col-6 col-sm-auto" v-if="!showQntyInput">
                <a
                  role="button"
                  class="minus"
                  v-on:click.prevent="minus"
                  v-ripple
                >
                  <v-icon>mdi-minus</v-icon>
                </a>
                <div
                  class="val-cnt"
                  @mousedown.stop
                  @click.stop.prevent=""
                  @dblclick="toggleShowInput"
                  v-long-press="300"
                  @long-press-start="toggleShowInput"
                >
                  <span class="val">{{ quantity }} {{ unit }}</span>
                  <span class="small">{{ quantityPerUnit }}</span>
                </div>
                <a
                  role="button"
                  class="plus"
                  v-on:click.prevent="plus"
                  v-ripple
                >
                  <v-icon>mdi-plus</v-icon>
                </a>
              </div>
              <div
                v-else
                class="qnty-manual-input rounded-md"
                :class="{ 'not-empty': quantity > 0 }"
                transition="fab-transition"
              >
                <a
                  role="button"
                  class="close"
                  @click.stop.prevent="toggleShowInput(false)"
                  @mousedown.stop
                >
                  <v-icon>mdi-close</v-icon>
                </a>
                <v-tooltip
                  bottom
                  :open-on-hover="false"
                  :open-on-click="true"
                  max-width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="qntyInput"
                      ref="manualInput"
                      class="manual-input"
                      hide-details
                      autofocus
                      outlined
                      dense
                      v-mask="'###'"
                      type="number"
                      @click.stop.prevent=""
                      @keyup.enter="toggleShowInput(true)"
                      @keyup.esc="toggleShowInput(false)"
                      @mousedown.stop
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <span
                    class="qntity-tooltip"
                    v-if="
                      product.productInfos.QTY_LOCKED != 'true' ||
                        (product.productInfos.QTY_LOCKED == 'true' &&
                          !modulus(qntyInput))
                    "
                    v-html="
                      $t('product.quantity.confirmQntity', {
                        qntyInput: this.qntyInput
                      })
                    "
                    @click="clicked"
                  >
                  </span>
                  <span
                    class="qntity-tooltip"
                    v-if="
                      product.productInfos.QTY_LOCKED == 'true' &&
                        modulus(qntyInput) &&
                        parseInt(qntyInput) <=
                          parseInt(this.product.productInfos.QTY_MULT)
                    "
                    v-html="
                      $t('product.quantity.editQntityMin', {
                        lowerInput: this.lowerInput
                      })
                    "
                    @click="clicked"
                  >
                  </span>

                  <span
                    class="qntity-tooltip"
                    v-if="
                      product.productInfos.QTY_LOCKED == 'true' &&
                        modulus(qntyInput) &&
                        parseInt(qntyInput) >
                          parseInt(this.product.productInfos.QTY_MULT)
                    "
                    v-html="
                      $t('product.quantity.editQntityMinMax', {
                        lowerInput: this.lowerInput,
                        higherInput: this.higherInput
                      })
                    "
                    @click="clicked"
                  >
                  </span>
                </v-tooltip>
                <a
                  role="button"
                  class="check"
                  @click.stop.prevent="toggleShowInput(true)"
                  @mousedown.stop
                >
                  <v-icon>mdi-check</v-icon>
                </a>
              </div>
              <div class="px-2 col-6 col-sm-auto d-flex flex-row">
                <v-btn
                  class="add-to-cart"
                  color="primary"
                  :x-large="$vuetify.breakpoint.smAndUp"
                  depressed
                  height="52"
                  @click="addToCart(quantity)"
                >
                  <span v-if="$vuetify.breakpoint.smAndUp"
                    >Aggiungi al Carrello</span
                  >
                  <v-icon>$cart</v-icon>
                </v-btn>
                <v-btn
                  v-if="enableAcqBox && product.logisticPalletItems"
                  :aria-label="
                    `Aggiungi un cartone da ${product.logisticPalletItems} al carrello`
                  "
                  color="primary"
                  outlined
                  class="btn-add-box ml-1"
                  small
                  @click.stop.prevent="
                    addToCart(product.logisticPalletItems, undefined)
                  "
                >
                  <div class="text-uppercase">
                    <v-icon color="primary">$cart</v-icon>
                    <div>Un collo</div>
                    <div class="small">
                      = {{ product.logisticPalletItems }} pz
                    </div>
                  </div>
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="isAuthenticated">
          <v-col
            sm="6"
            cols="12"
            class="pa-2"
            v-if="product.metaData.product_description.tecnich_doc_id"
          >
            <v-btn
              class="pdf-button-doc"
              color="primary"
              x-large
              outlined
              target="datasheet"
              rel="noopener noreferrer"
              :href="
                '/ebsn/api/pimcore-document/download?asset_id=' +
                  product.metaData.product_description.tecnich_doc_id
              "
              @click="
                openDownloadLink(
                  $event,
                  '/ebsn/api/pimcore-document/download?asset_id=' +
                    product.metaData.product_description.tecnich_doc_id,
                  product.metaData.product_description.tecnich_doc_id
                )
              "
            >
              Scarica la Scheda Tecnica
            </v-btn>
          </v-col>
          <v-col
            sm="6"
            cols="12"
            class="pa-2"
            v-if="product.metaData.product_description.security_doc_id"
          >
            <v-btn
              class="pdf-button-doc"
              color="primary"
              x-large
              outlined
              target="datasheet"
              rel="noopener noreferrer"
              :href="
                '/ebsn/api/pimcore-document/download?asset_id=' +
                  product.metaData.product_description.security_doc_id
              "
              @click="
                openDownloadLink(
                  $event,
                  '/ebsn/api/pimcore-document/download?asset_id=' +
                    product.metaData.product_description.security_doc_id,
                  product.metaData.product_description.security_doc_id
                )
              "
            >
              Scarica la Scheda della Sicurezza
            </v-btn>
          </v-col>
          <v-col
            sm="6"
            cols="12"
            class="pa-2"
            v-if="product.metaData.product_description.comparative_doc_id"
          >
            <v-btn
              class="pdf-button-doc"
              color="primary"
              x-large
              outlined
              target="datasheet"
              rel="noopener noreferrer"
              :href="
                '/ebsn/api/pimcore-document/download?asset_id=' +
                  product.metaData.product_description.comparative_doc_id
              "
              @click="
                openDownloadLink(
                  $event,
                  '/ebsn/api/pimcore-document/download?asset_id=' +
                    product.metaData.product_description.comparative_doc_id,
                  product.metaData.product_description.comparative_doc_id
                )
              "
            >
              Scarica la Scheda Comparativa
            </v-btn>
          </v-col>
          <v-col
            sm="6"
            cols="12"
            class="pa-2"
            v-if="product.metaData.product_description.ingredients_doc_id"
          >
            <v-btn
              class="pdf-button-doc"
              color="primary"
              x-large
              outlined
              target="datasheet"
              rel="noopener noreferrer"
              :href="
                '/ebsn/api/pimcore-document/download?asset_id=' +
                  product.metaData.product_description.ingredients_doc_id
              "
              @click="
                openDownloadLink(
                  $event,
                  '/ebsn/api/pimcore-document/download?asset_id=' +
                    product.metaData.product_description.ingredients_doc_id,
                  product.metaData.product_description.ingredients_doc_id
                )
              "
            >
              Scarica la Scheda degli Ingredienti
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>
<style scoped lang="scss">
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .add-to-cart {
    width: auto;
  }
}
.manual-input {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.qntity-tooltip {
  color: $primary;
  font-size: 13px;
}
</style>

<script>
import ProductPrice from "@/components/product/ProductPrice.vue";
import ProductPromo from "@/components/product/ProductPromo.vue";
import ProductClass from "@/components/product/ProductClass.vue";
import ProductImages from "@/components/product/ProductImages.vue";
import ProductAcqLimit from "./ProductAcqLimit.vue";
import LongPress from "vue-directive-long-press";

import productMixin from "~/mixins/product";
import clickHandler from "~/mixins/clickHandler";

import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import { formatProductCode } from "@/service/grosmarketUtils";
import { handleReceiptDownload } from "~/service/ebsn.js";

export default {
  name: "ProductDetail",
  props: ["selectedImage", "product"],
  components: {
    ProductPrice,
    ProductPromo,
    ProductClass,
    ProductImages,
    ProductAcqLimit
  },
  mixins: [productMixin, clickHandler],
  directives: {
    "long-press": LongPress,
    mask: mask
  },
  data() {
    return {
      imageDetailDialog: false
    };
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId",
      isAuthenticated: "cart/isAuthenticated"
    }),
    item() {
      return null;
    },
    enableAcqBox() {
      return (
        this.product.productInfos?.ENABLE_ACQ_BOX &&
        this.product.productInfos?.ENABLE_ACQ_BOX !== "no"
      );
    },
    // productClasses() {
    //   return this.product &&
    //     this.product.productClasses &&
    //     this.product.productClasses.length
    //     ? this.product.productClasses.filter(p => p.productClassId !== 10078)
    //     : [];
    // },
    productCode() {
      return formatProductCode(this.product);
    }
  },
  methods: {
    selectImage(media) {
      this.$emit("selectImage", media);
    },
    openImageDialog() {
      this.imageDetailDialog = true;
    },
    openDownloadLink(e, url, name) {
      if (this.$platform_is_cordova) {
        e.preventDefault();
        handleReceiptDownload(url, name);
      } else {
        window.open(url, "_blank");
      }
    }
  }
};
</script>
