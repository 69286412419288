<template>
  <div>
    <v-container
      v-if="
        category.categoryId === product.categoryId && headerBanner.length > 0
      "
    >
      <ProposalSlider :proposals="headerBanner" :key="'header-banner_' + key" />
    </v-container>
    <v-container class="hidden-sm-and-down">
      <v-breadcrumbs :items="vBreadcrumb"></v-breadcrumbs>
    </v-container>
    <SocialSharing
      v-if="product && product.productId"
      :title="sharingTitle"
      :description="sharingDescription"
    />
    <v-container v-if="product" class="product-detail">
      <ProductDetail
        v-if="product.productId"
        :product="product"
        :selectedImage="selectedImage"
        :key="'product_detail_' + key"
        @selectImage="selectImage"
      />
      <ProductDescription
        v-if="product.productId"
        :product="product"
        :key="'product_description_' + key"
        :productMetadata="product.metaData"
        :ingredientList="product.ingredientList"
      />
    </v-container>
    <div class="white product-slider-wrapper">
      <div v-if="product.variations" class="product-with-border">
        <ProductListSlider
          :productList="product.variations"
          :key="product.productId + '_3'"
          title="Altri prodotti della stessa linea:"
          :paginationClass="'swiper-pagination-linea'"
          position="product_variations"
        />
      </div>
      <div class="secondary lighten-2 product-slider-wrapper">
        <ProductListSlider
          :layout="220"
          :limit="12"
          v-if="product.productId"
          :productId="product.productId"
          :key="product.productId + '_1'"
          title="Ti potrebbero interessare anche:"
          :paginationClass="'swiper-pagination-interesse'"
          position="product_correlati"
        />
      </div>
      <div class="secondary product-slider-wrapper">
        <ProductListSlider
          :layout="223"
          :limit="12"
          v-if="product.productId"
          :productId="product.productId"
          :key="product.productId + '_2'"
          title="Suggeriti per te:"
          :paginationClass="'swiper-pagination-suggeriti'"
          position="product_suggeriti"
        />
      </div>
    </div>
    <v-container
      v-if="
        category.categoryId === product.categoryId && footerBanner.length > 0
      "
    >
      <ProposalSlider
        :proposals="footerBanner"
        :key="'footer-banner_' + key"
        position="product_footer_banner"
      />
    </v-container>
  </div>
</template>

<script>
import ProductDescription from "@/components/product/ProductDescription.vue";
import ProductDetail from "@/components/product/ProductDetail.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import SocialSharing from "@/components/product/SocialSharing.vue";
import ProposalSlider from "@/components/proposal/ProposalSlider.vue";

import categoryMixin from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";
import { getFullName } from "@/customEbsn";

import analyticsService from "~/service/analyticsService";

import { mapProposal } from "~/service/ebsn.js";

import clone from "lodash/clone";
import get from "lodash/get";

export default {
  name: "Product",
  mixins: [categoryMixin, deliveryReactive],
  data() {
    return {
      product: {
        productId: null,
        name: "",
        shortDescr: "",
        description: ""
      },
      selectedImage: null,
      key: 1
    };
  },
  components: {
    ProductDescription,
    ProductDetail,
    ProductListSlider,
    ProposalSlider,
    SocialSharing
  },
  computed: {
    ...mapProposal({
      headerBanner: "header-banner",
      footerBanner: "footer-banner"
    }),
    sharingTitle() {
      return this.product.name;
    },
    sharingDescription() {
      return (
        this.product.name +
        " " +
        this.product.description +
        " " +
        this.product.shortDescr
      );
    },
    vBreadcrumb() {
      let b = clone(this.breadcrumb);
      if (this.product) {
        b.push({
          to: {
            name: "Product",
            params: {
              volantino: this.product.name
            }
          },
          text: this.product.name,
          exact: true
        });
      }
      return b;
    }
  },
  methods: {
    async setProduct(product) {
      // var data = await ProductService.getProductBySlug(this.slug);
      if (product) {
        this.product = product;
        // if (this.product.categoryId) {
        //   this.setCategory(this.product.categoryId);
        // }
        if (this.product && this.product.media && this.product.media.length) {
          this.selectImage(this.product.media[0]);
        }
        this.key += 1;
        analyticsService.viewProductDetail(this.product);
      } else {
        this.showNoProductCard = true;
      }
    },
    async setCategory(categoryId) {
      this.$store.dispatch("category/setCategory", {
        slug: categoryId,
        reset: false
      });
    },
    async selectImage(image) {
      this.selectedImage = image;
      this.selectedImage.thumb = image.medium;
    },
    async reload() {
      await this.resetFilters(this.category.slug);
      await this.getProduct();
      this.key += 1;
    }
  },
  async mounted() {
    let _this = this;
    _this.slug = _this.$route.params.slug;
    this.setProduct(_this.$route.params.product);
  },
  watch: {
    async $route(to) {
      if (to.params.slug != this.slug) {
        this.slug = to.params.slug;
        this.setProduct(this.$route.params.product);
      }
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.gtm,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.product,
            "metaData.product_seo.SEO_DESCRIPTION",
            getFullName(this.product) +
              " online. " +
              this.category.name +
              " in offerta e promozioni su GrosMarket GrosMarket Cash&Carry con consegna a casa, ritiro in negozio e ritiro in locker."
          )
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: get(this.product, "metaData.product_seo.SEO_KEYWORDS")
        }
      ]
    };
  }
};
</script>
