<template>
  <div class="social-sharing-container">
    <div class="social-sharing-container-mobile">
      <!-- mobile -->
      <v-speed-dial
        v-if="$vuetify.breakpoint.xsOnly"
        v-model="fab"
        :right="true"
        fixed
        direction="top"
        transition="slide-y-reverse-transition"
        class="social-filter"
      >
        <template v-slot:activator>
          <!-- chiuso -->
          <v-btn v-model="fab" color="secondary" fab>
            <v-icon v-if="fab">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-share-variant
            </v-icon>
          </v-btn>
        </template>
        <!-- aperto -->
        <ShareNetwork
          v-for="network in networks"
          :key="network.network"
          :network="network.network"
          :style="{ backgroundColor: network.color }"
          class="social-sharing"
          :url="sharingUrl"
          :title="title"
          :description="description"
        >
          <v-icon class="white--text">{{ network.icon }}</v-icon>
        </ShareNetwork>
        <a
          style="backgroundColor: #AEAEAE"
          class="social-sharing"
          @click="doPrint"
        >
          <v-icon class="white--text">mdi-printer</v-icon>
        </a>
        <div class="social-text mb-2 ml-2">CONDIVIDI SU:&nbsp;</div>
      </v-speed-dial>
    </div>
    <!-- desktop -->
    <div
      class="social-sharing-container-desktop"
      v-if="!$vuetify.breakpoint.xsOnly"
    >
      <div class="social-text mb-2">CONDIVIDI SU:&nbsp;</div>
      <!-- hashtags="hashtag1,hashtag2" 
    only works on fb/twitter -->
      <ShareNetwork
        v-for="network in networks"
        :key="network.network"
        :network="network.network"
        :style="{ backgroundColor: network.color }"
        class="social-sharing"
        :url="sharingUrl"
        :title="title"
        :description="description"
      >
        <v-icon class="white--text">{{ network.icon }}</v-icon>
      </ShareNetwork>
      <a
        style="backgroundColor: #AEAEAE"
        class="social-sharing"
        @click="doPrint"
      >
        <v-icon class="white--text">mdi-printer</v-icon>
      </a>
    </div>
  </div>
  <!-- </v-bottom-navigation> -->
</template>
<style scoped lang="scss">
.social-sharing-container {
  .social-sharing-container-desktop {
    position: fixed;
    right: 0px;
    top: 200px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .social-filter {
    bottom: 170px !important;
    .v-speed-dial__list {
      min-width: 70px !important;
      left: initial;
      right: 0;
    }
  }
  .social-text {
    font-size: 10px;
    color: $text-color;
  }
  .social-sharing {
    height: 45px;
    width: 45px;
    padding: 11px;
  }
  a {
    color: transparent;
  }
}
</style>

<script>
export default {
  name: "SocialSharing",
  data() {
    return {
      fab: false,
      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: "mdi-facebook",
          color: "#1877f2"
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "mdi-whatsapp",
          color: "#25d366"
        },
        // {
        //   network: "telegram",
        //   name: "Telegram",
        //   icon: "",
        //   color: "#0088cc"
        // },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "mdi-linkedin",
          color: "#007bb5"
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "mdi-twitter",
          color: "#1da1f2"
        },
        {
          network: "email",
          name: "Email",
          icon: "mdi-email",
          color: "#333333"
        }
        // { network: 'baidu', name: 'Baidu', icon: 'fas fah fa-lg fa-paw', color: '#2529d8' },
        // { network: 'buffer', name: 'Buffer', icon: 'fab fah fa-lg fa-buffer', color: '#323b43' },
        // { network: 'evernote', name: 'Evernote', icon: 'fab fah fa-lg fa-evernote', color: '#2dbe60' },
        // { network: 'flipboard', name: 'Flipboard', icon: 'fab fah fa-lg fa-flipboard', color: '#e12828' },
        // { network: 'hackernews', name: 'HackerNews', icon: 'fab fah fa-lg fa-hacker-news', color: '#ff4000' },
        // { network: 'instapaper', name: 'Instapaper', icon: 'fas fah fa-lg fa-italic', color: '#428bca' },
        // { network: 'line', name: 'Line', icon: 'fab fah fa-lg fa-line', color: '#00c300' },
        // { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff' },
        // { network: 'odnoklassniki', name: 'Odnoklassniki', icon: 'fab fah fa-lg fa-odnoklassniki', color: '#ed812b' },
        // { network: 'pinterest', name: 'Pinterest', icon: 'fab fah fa-lg fa-pinterest', color: '#bd081c' },
        // { network: 'pocket', name: 'Pocket', icon: 'fab fah fa-lg fa-get-pocket', color: '#ef4056' },
        // { network: 'quora', name: 'Quora', icon: 'fab fah fa-lg fa-quora', color: '#a82400' },
        // { network: 'reddit', name: 'Reddit', icon: 'fab fah fa-lg fa-reddit-alien', color: '#ff4500' },
        // { network: 'skype', name: 'Skype', icon: 'fab fah fa-lg fa-skype', color: '#00aff0' },
        // { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
        // { network: 'stumbleupon', name: 'StumbleUpon', icon: 'fab fah fa-lg fa-stumbleupon', color: '#eb4924' },
        // { network: 'tumblr', name: 'Tumblr', icon: 'fab fah fa-lg fa-tumblr', color: '#35465c' },
        // { network: 'viber', name: 'Viber', icon: 'fab fah fa-lg fa-viber', color: '#59267c' },
        // { network: 'vk', name: 'Vk', icon: 'fab fah fa-lg fa-vk', color: '#4a76a8' },
        // { network: 'weibo', name: 'Weibo', icon: 'fab fah fa-lg fa-weibo', color: '#e9152d' },
        // { network: 'wordpress', name: 'Wordpress', icon: 'fab fah fa-lg fa-wordpress', color: '#21759b' },
        // { network: 'xing', name: 'Xing', icon: 'fab fah fa-lg fa-xing', color: '#026466' },
        // { network: 'yammer', name: 'Yammer', icon: 'fab fah fa-lg fa-yammer', color: '#0072c6' },
        // { network: 'fakeblock', name: 'Custom Network', icon: 'fab fah fa-lg fa-vuejs', color: '#41b883' }
      ]
    };
  },
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true }
  },
  computed: {
    sharingUrl() {
      return window.location.href;
    }
  },
  methods: {
    doPrint() {
      window.print();
    }
  }
};
</script>
