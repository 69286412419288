<template>
  <iframe
    v-if="videoUrl"
    :width="$vuetify.breakpoint.xs ? '100%' : 640"
    height="390"
    :src="videoUrl"
    frameborder="0"
    title="YouTube video player"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  />
</template>

<script>
export default {
  name: "MediaTpl",

  props: {
    content: { type: String, required: true }
  },
  data() {
    return {
      parsedContent: null,
      videoUrl: null,
      error: null
    };
  },
  mounted() {
    this.handleParsedContent();
  },
  methods: {
    handleParsedContent() {
      if (typeof this.content == "string") {
        this.videoUrl = this.content;
      } else {
        this.parsedContent = JSON.parse(this.content);
        try {
          // gestione della lettura dell'url del video
          this.error = null;
          this.videoUrl = this.parsedContent.url;
          // YOUTUBE
          if (this.parsedContent.url.search("youtube.com") > -1) {
            // LINK DIRETTO (watch)
            if (this.parsedContent.url.search("watch?") > -1) {
              this.videoUrl = this.parsedContent.url.replace(
                "watch?v=",
                "embed/"
              );
            } else {
              // LINK PER EMBED (embed)
              this.videoUrl = this.parsedContent.url;
            }
          }
          // VIMEO
          if (
            this.content.search("https://player.vimeo.com/video") < 0 &&
            this.content.search("vimeo.com") > -1
          ) {
            // LINK DIRETTO da covertire in URL per embed

            let decodedUrl = new URL(this.content);
            if (decodedUrl) {
              this.videoUrl = `https://player.vimeo.com/video${decodedUrl.pathname}`;
            } else {
              this.error = this.$t("errors.errorParsingVideoUrl");
            }
          }
        } catch (error) {
          console.log(error);
          this.error = error || this.$t("errors.errorParsingVideoUrl");
        }
      }
    }
  }
};
</script>
